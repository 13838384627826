<template>
  <div v-if="isMobile" class="absolute mr-5">
    <div class="my-6">
      <h3 class="font-bold">Criar um novo vínculo</h3>
      <span class="text-sm text-gray-500"
        >Preencha as informações abaixo e clique no botão <b>criar</b> para
        salvar as alterações.
      </span>
    </div>

    <div class="card shadow-sm bg-white border p-8">
      <div class="block">
        <div class="flex-1 mr-3 my-3">
          <Input
            v-model="data.evento"
            id="evento"
            type="datalist"
            label="Eventos"
            placeholder="Eventos"
            :data="eventos"
          />
        </div>
        <div class="flex-1 mr-3  my-3">
          <Input
            v-model="data.usuario"
            id="usuario"
            type="datalist"
            label="Usuário"
            placeholder="Usuário"
            :data="usuarios"
          />
        </div>

        <div class="flex-1  mr-3 my-3">
          <Input
            v-model="data.perfil"
            id="tipo"
            type="datalist"
            :data="tipos"
            placeholder="Tipo"
            label="Tipo"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-5">
      <Button @click="create" color="primary" text="Criar"  class="btn"/>
    </div>
  </div>

  <div v-else>
    <div class="my-6">
      <h3 class="font-bold">Criar um novo vínculo</h3>
      <span class="text-sm text-gray-500"
        >Preencha as informações abaixo e clique no botão <b>criar</b> para
        salvar as alterações.
      </span>
    </div>

    <div class="card shadow-sm bg-white border p-8">
      <div class="flex">
        <div class="flex-1 mr-3">
          <Input
            v-model="data.evento"
            id="evento"
            type="datalist"
            label="Eventos"
            placeholder="Eventos"
            :data="eventos"
          />
        </div>
        <div class="flex-1 mr-3">
          <Input
            v-model="data.usuario"
            id="usuario"
            type="datalist"
            label="Usuário"
            placeholder="Usuário"
            :data="usuarios"
          />
        </div>

        <div class="flex-1">
          <Input
            v-model="data.perfil"
            id="tipo"
            type="datalist"
            :data="tipos"
            placeholder="Tipo"
            label="Tipo"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-5">
      <Button @click="create" color="primary" text="Criar" />
    </div>
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET, POST } from "../../services/api";
import { isEmpty } from "../../services/validate";
import { useRouter } from "vue-router";
import { toDate } from "@/services/formater";

export default {
  name: "CriarVinculo",
  components: {
    Input,
    Button,
  },
  data() {
    return {
      isMobile: false,
    };
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },

  setup() {
    const data = ref({
      "evento": "",
      "usuario": "",
      "perfil": "",
    });
    const loader = inject("loading");
    const alert = inject("alert");
    const router = useRouter();
    const eventos = ref([]);
    const tipos = ref([]);
    const usuarios = ref([]);

    const create = async () => {

      const obj = { 
        ... data.value,
        "id_evento" : data.value.evento,
        "id_usuario" : data.value.usuario,
        "id_perfil" : data.value.perfil,
      }

      delete obj.evento
      delete obj.usuario
      delete obj.perfil


      const [eventoSelecionado] = eventos.value.filter(
        (evento) => evento.label == data.value.evento
      );

      eventoSelecionado
        ? (obj["id_evento"] = eventoSelecionado.value)
        : (obj["id_evento"] = "");

      const [tipoSelecionado] = tipos.value.filter(
        (tipo) => tipo.label == data.value.perfil
      );

      tipoSelecionado
        ? (obj["id_perfil"] = tipoSelecionado.value)
        : (obj["id_perfil"] = "");

      const [usuarioSelecionado] = usuarios.value.filter(
        (usuario) => usuario.label == data.value.usuario
      );

      usuarioSelecionado
        ? (obj["id_usuario"]= usuarioSelecionado.value)
        : (obj["id_usuario"] = "");

      const validate = isEmpty(data.value);
      if (validate) {
        alert.open("Atenção!", validate, "warning");

        data.value["evento"] = null;
        data.value["usuario"] = null;
        data.value["perfil"] = null;
      } else {
        loader.open();
        try {
          await POST("usuarios_vinculos", obj);
          alert.open("Sucesso!", `Vínculo criado com sucesso!`, "success");

          data.value["evento"] = null;
          data.value["perfil"] = null;
          data.value["usuario"] = null;

          router.push("/vinculos");
        } catch (e) {
          alert.open("Atenção!", e, "danger");
        }
        loader.close();
      }
    };

    onMounted(async () => {
      loader.open();
      const eventosRes = await GET(`event`);
      eventosRes.forEach((evento) =>
        eventos.value.push({
          label: evento.nome + " - " + toDate(evento.data),
          value: evento.id,
        })
      );

      const usuariosRes = await GET("usuarios");
      usuariosRes.forEach((usuario) =>
        usuarios.value.push({ label: usuario.usuario, value: usuario.id })
      );

      const perfilRes = await GET("perfis");
      perfilRes.forEach((tipo) =>
        tipos.value.push({ label: tipo.nome, value: tipo.id })
      );
      //TODO: buscar os tipos de produto e usuarios para o datalist
      loader.close();
    });

    return {
      data,
      create,
      eventos,
      tipos,
      usuarios,
    };
  },
};
</script>

<style>
  .btn{
    width: 100%;
    align-items: center;
    justify-content: center;
  }
</style>
